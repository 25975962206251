import {atom, selector} from 'recoil';
import {INIT_LAT, INIT_LNG} from '../helper/constants'

export const stateMarker = atom({
    key: "marker",
    default: {
        latitude: INIT_LAT, 
        longitude: INIT_LNG,
        h3: '',
        hex5: '',
        boundary: [],
        resolution: 0
    }
})

export function formatAddr(hex5) {

    const capitalize = (s) => {
        return s && s[0].toUpperCase() + s.slice(1);
    }
  
    const arr = hex5.split(" ")
    let str_hex5 = []

    arr.forEach((token, i) => {
        arr[i] = capitalize(token)
    });

    if ( arr.length > 2 ) {
        str_hex5.push( arr.slice(0,-2).join(" ") )
        str_hex5.push( arr.slice(-2).join(" ") )
    } else {
        str_hex5.push( arr.join(" ") )
    }

    return str_hex5
}

export const stateHex5Addr = selector({
    key: 'hex5Addr',
    get: ({get}) => {
        const hex5 = get(stateMarker).hex5
        return formatAddr(hex5)
    },
})