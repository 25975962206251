import Map from "./components/Map"
import BasicSnackbar from "./components/BasicSnackbar"
import {stateSnackBar} from "./recoil/SnackBar"
import {useEffect} from 'react'
import {useSetRecoilState, useRecoilState, useRecoilValue} from 'recoil'
import { GOTO_zoom, INIT_ZOOM_OFFSET, 
  INIT_LAT, INIT_LNG, INIT_SPEED, mapStyleActions, 
  GEO2HEX_API_URL, HEX2GEO_API_URL } from './helper/constants'
import { stateViewPort, stateMapStyle } from './recoil/Map'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import SpeedDial from '@mui/material/SpeedDial'
// import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Div100vh from 'react-div-100vh'
import Hex5Coder from "./components/Hex5Coder"
import { stateMarker, formatAddr } from "./recoil/Marker"
import axios from 'axios'
import { FlyToInterpolator } from 'deck.gl'
import InfoBox from "./components/InfoBox"
// import SlideshowIcon from '@mui/icons-material/Slideshow'
import { stateOpenInfoBox } from "./recoil/InfoBox"
import {stateInputHex5} from './recoil/Hex5Coder'
import IosShareIcon from '@mui/icons-material/IosShare'
import { stateHex5Addr } from "./recoil/Marker"
import { useParams } from 'react-router-dom'
import SvgHex5LogoSimple from './components/LogoSimple'
import LinkIcon from '@mui/icons-material/Link'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DirectionsIcon from '@mui/icons-material/Directions'
// import Tooltip from '@mui/material/Tooltip'

const actions = [
  { icon: <LinkIcon />, name: 'link', desc: 'Copy Link' },
  { icon: <ContentCopyIcon />, name: 'address', desc: 'Copy Address' },
  { icon: <DirectionsIcon />, name: 'directions', desc: 'Google Directions' },
];

export default function App() { 

  let myParams = useParams()
  // let hex5_addr = myParams['hex5'].replace(/\./gi,' ')

  const [viewPort, setViewPort] = useRecoilState(stateViewPort)  
  const [mapStyle, setMapStyle] = useRecoilState(stateMapStyle)
  const setDataSnackbar = useSetRecoilState(stateSnackBar)
  const [selectedMarker, setMarker] = useRecoilState(stateMarker)
  const setOpenInfoBox = useSetRecoilState(stateOpenInfoBox)
  const setInputHex5 = useSetRecoilState(stateInputHex5)
  const hex5Addr = useRecoilValue(stateHex5Addr)

  // -------------------------------------------------------
  // use effects to set the initial marker
  // executed only once
  // -------------------------------------------------------  
  useEffect(() => {
    setMarkerURL(myParams)
  }, [""]) 

  // -------------------------------------------------------
  // handle action
  // -------------------------------------------------------  
  function handleAction(action_name) {
    if (action_name === 'directions') {
      window.open('https://www.google.com/maps/dir/?api=1&destination='+ selectedMarker.latitude + ',' + selectedMarker.longitude)
      // window.open('https://maps.google.com/?daddr='+ selectedMarker.latitude + ',' + selectedMarker.longitude)

      // SAFARI has issues with opening window in async calss
      // setDataSnackbar({
      //   open: true,
      //   type: "info",
      //   msg: "Waiting for your location"
      // })
      // var windowReference = window.open()
      // navigator.geolocation.getCurrentPosition(dirCurrentMarker(windowReference), dirToMarker(windowReference))
    }

    if (action_name === 'link') {
      copyLinkClipboard()
    }

    if (action_name === 'address') {
      copyToClipboard()
    }
  }

  // // redirect to google directions with current location
  // function dirCurrentMarker(position, windowReference) {
  //   let googleURL = 'https://www.google.com/maps/dir/'
  //   googleURL += position.coords.latitude + ',' + position.coords.longitude
  //   googleURL += '/' + selectedMarker.latitude + ',' + selectedMarker.longitude
  //   windowReference.location = googleURL
  // }

  //   // redirect to google directions without start point
  // function dirToMarker(error, windowReference) {
  //   let googleURL = 'https://www.google.com/maps/dir/?api=1&destination='
  //   googleURL += selectedMarker.latitude + ',' + selectedMarker.longitude
  //   windowReference.location = googleURL
  // }


  // -------------------------------------------------------
  // set initial location based on the URL
  // -------------------------------------------------------  
  async function setMarkerURL(myParams) {

    // form the hex5 address
    let hex5_addr = ''
    for (let i = 1; i < 6; i++) {
      if (myParams.hasOwnProperty('hex'+i)) {
        if (hex5_addr !== '') {
          hex5_addr += ' '
        }
        hex5_addr += myParams['hex'+i]
      } else {
        break
      }
    }    

    console.log(hex5_addr)
    if (hex5_addr.length > 0) {
      // this is probably a hex5 address
      await axios.get(HEX2GEO_API_URL + "?hex5_addr=" + hex5_addr)
      .then(result => {
          if ('status' in result.data) {
              if (result.data.status === 'ok') {
                  console.log( result.data.hex5 + ' -> ' + result.data.lat + ',' + result.data.lng )
                  setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
                      hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
                      boundary: result.data.boundary} )
                  setViewPort( {...viewPort, longitude: result.data.lng, 
                      latitude: result.data.lat, 
                      zoom: result.data.resolution+INIT_ZOOM_OFFSET    
                  })
                  return true
              } else {
                  setDataSnackbar({
                    open: true,
                    type: "warning",
                    msg: result.data.desc
                  })    
                  setMarkerDefault()              
              }
          }
      })
      .catch(error => {
        setDataSnackbar({
          open: true,
          type: "warning",
          msg: error.response.data.error
        })    
        setMarkerDefault()
      })    
    } else {
      setMarkerDefault()
    }

  }

  // -------------------------------------------------------
  // set initial location based on the default values
  // -------------------------------------------------------    
  async function setMarkerDefault() {

    await axios.get(GEO2HEX_API_URL + "?lat=" + INIT_LAT + "&lng=" + INIT_LNG)
    .then(result => {
      if ('status' in result.data) {
        if (result.data.status === 'ok') {      
          setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
            hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
            boundary: result.data.boundary} )
        } else {
          setDataSnackbar({
            open: true,
            type: "warning",
            msg: result.data.desc
          })          
        }  
      }                
    })
    .catch(error => {
      setDataSnackbar({
        open: true,
        type: "error",
        msg: error.response.data.error
      })
    })         

  }

  // -------------------------------------------------------
  // get current localization 
  // -------------------------------------------------------  
  function getLocation() {
    setDataSnackbar({
      open: true,
      type: "info",
      msg: "Waiting for your location"
    })    
    // if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(setLocation, errorLocation)
    // } else { 
      // setDataSnackbar("Geolocation is not supported by this browser.")
    // }
  }

  // error function
  function errorLocation(error) {
    setDataSnackbar(error.code + " : " + error.message)
  }
  
  // set location
  async function setLocation(position) {   
    await axios.get(GEO2HEX_API_URL + "?lat=" + position.coords.latitude + "&lng=" + position.coords.longitude)
    .then(result => {
      if ('status' in result.data) {
        if (result.data.status === 'ok') {
          console.log( position.coords.latitude + ',' +position.coords.longitude + ' -> ' + result.data.hex5 )
          setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
            hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
            boundary: result.data.boundary} )
          setInputHex5( formatAddr(result.data.hex5).join(" ") )
          setViewPort({...viewPort, zoom: GOTO_zoom, 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude,
            transitionDuration: INIT_SPEED,
            transitionInterpolator: new FlyToInterpolator()    
          })            
        } else {
          setDataSnackbar({
            open: true,
            type: "warning",
            msg: result.data.desc
          })          
        }  
      }          
    })
    .catch(error => {
      setDataSnackbar({
        open: true,
        type: "error",
        msg: error.response.data.error
      })
    })  
  }

  // -------------------------------------------------------
  // copy link to clipboard
  // ------------------------------------------------------- 
  const copyLinkClipboard = async () => {

    // this only works over https or localhost
    try {
      await navigator.clipboard.writeText("https://hex5.net/" + hex5Addr.join(' ').replace(/\s/gi,'/'))
      setDataSnackbar({
        open: true,
        type: "info",
        msg: "Link copied to clipboard"
      })
    } catch (err) {
      setDataSnackbar({
        open: true,
        type: "warning",
        msg: "Link couldn't be copied to clipboard"
      })
    }
  }

  // -------------------------------------------------------
  // copy address to clipboard
  // ------------------------------------------------------- 
  const copyToClipboard = async () => {
    // this only works over https or localhost
    try {
      await navigator.clipboard.writeText(hex5Addr.join(" "))
      setDataSnackbar({
        open: true,
        type: "info",
        msg: "Address copied to clipboard"
      })
    } catch (err) {
      // document.execCommand('copy', true, hex5Addr.join(" "))
      setDataSnackbar({
        open: true,
        type: "warning",
        msg: "Address couldn't be copied to clipboard"
      })
      // console.log(err)
    }
  }  
  
  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------  

  return (
    <Div100vh>

      <SpeedDial
        ariaLabel="MapStyle"
        sx={{ position: 'absolute', bottom: 20, left: 20 }}
        icon={<IosShareIcon />}
        // onClick={() => copyLinkClipboard(true)}
      >
          {actions.map((action) => (
            // <Tooltip open={true} title={action.desc} placement="right" key={action.name}>
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.desc}
              tooltipPlacement={'right'}
              tooltipOpen
              onClick={() => handleAction(action.name)}
            />
            // </Tooltip>
          ))}
      </SpeedDial>    

      <SpeedDial
        ariaLabel="InfoBox"
        sx={{ position: 'absolute', bottom: 20, left: 100 }}
        //icon={<SlideshowIcon />}
        icon={<SvgHex5LogoSimple />}
        onClick={() => setOpenInfoBox(true)}
      >
      </SpeedDial>

      <SpeedDial
        ariaLabel="MapStyle"
        sx={{ position: 'absolute', bottom: 20, right: 100 }}
        icon={mapStyleActions[1-mapStyle].icon}
        onClick={() => setMapStyle(1-mapStyle)}
      >
      </SpeedDial>

      <SpeedDial
        ariaLabel="Current Location"
        sx={{ position: 'absolute', bottom: 20, right: 20 }}
        icon={<GpsFixedIcon />}
        onClick={() => getLocation()}
      >
      </SpeedDial>          

      <div style={{ position: "absolute", top: 20, right: 20, zIndex: 10 }}>
        <Hex5Coder />
      </div>

      <Map />
      <InfoBox></InfoBox>
      <BasicSnackbar></BasicSnackbar>
    </Div100vh>
  )
}
