import React from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil";
import App from "./App"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import "mapbox-gl/dist/mapbox-gl.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./geocoder.css"
import "./index.css"

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Routes>
        {/* <Route path="/:hex5" element={<AppRoute />} /> */}
        <Route path="/:hex1" element={<App />} />
        <Route path="/:hex1/:hex2" element={<App />} />
        <Route path="/:hex1/:hex2/:hex3" element={<App />} />
        <Route path="/:hex1/:hex2/:hex3/:hex4" element={<App />} />
        <Route path="/:hex1/:hex2/:hex3/:hex4/:hex5" element={<App />} />
        <Route path="*" element={<App />} /> 
        </Routes>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
)

