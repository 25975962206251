import React, {useState, useEffect} from "react"
import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil'
import Box from '@mui/material/Box'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Button from '@mui/material/Button'
import { stateOpenInfoBox } from "../recoil/InfoBox"
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { GEO2HEX_API_URL, INIT_ZOOM_OFFSET, INIT_SPEED, demoList } from "../helper/constants";
import {stateViewPort, stateMapStyle} from '../recoil/Map'
import axios from "axios";
import { stateMarker, stateHex5Addr, formatAddr } from '../recoil/Marker'
import { FlyToInterpolator } from 'deck.gl'
import { stateSnackBar } from '../recoil/SnackBar'
import { stateInputHex5 } from '../recoil/Hex5Coder'

// -------------------------------------------------------
// box style
// -------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  boxShadow: 12,
  p: 4,
}

// -------------------------------------------------------
// TabPanel
// -------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ m: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// -------------------------------------------------------
// a11yProps
// -------------------------------------------------------
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// -------------------------------------------------------
// ExamplesList
// -------------------------------------------------------
function ExamplesList(props) {

  const setOpenInfoBox = useSetRecoilState(stateOpenInfoBox)
  // const [mapStyle, setMapStyle] = useRecoilState(stateMapStyle)
  const [selectedMarker, setMarker] = useRecoilState(stateMarker)
  const [viewPort, setViewPort] = useRecoilState(stateViewPort)  
  const setDataSnackbar = useSetRecoilState(stateSnackBar)
  const setInputHex5 = useSetRecoilState(stateInputHex5)

  const index = props.index

  const handleClick = async (lat, lng) => {

    await axios.get(GEO2HEX_API_URL + "?lat=" + lat + "&lng=" + lng)
    .then(result => {
      if ('status' in result.data) {
        if (result.data.status === 'ok') {
            setOpenInfoBox(false)
            setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
                hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
                boundary: result.data.boundary} )
            setViewPort( {...viewPort, longitude: result.data.lng, 
                latitude: result.data.lat, 
                zoom: result.data.resolution+INIT_ZOOM_OFFSET,
                transitionDuration: INIT_SPEED,
                transitionInterpolator: new FlyToInterpolator() 
            })
            setInputHex5( formatAddr(result.data.hex5).join(" ") )
        } else {
            setDataSnackbar({
              open: true,
              type: "warning",
              msg: result.desc
            })
        }
      }                                                
    })       
    .catch(error => {
      setDataSnackbar({
        open: true,
        type: "error",
        msg: error.response.data.error
      })
    })
  }

  return (
    demoList[index].examples.map( example => { 
        return (
          <ListItem key={example.name} sx={{ p: 0 }}>
            <ListItemButton onClick={() => handleClick(example.lat, example.lng)}>
            <ListItemText primary={example.name} secondary={example.lat + ', ' + example.lng} />
            </ListItemButton>
          </ListItem>
    )})
  )
}


// -------------------------------------------------------
// InfoBox
// -------------------------------------------------------
export default function InfoBox() {
  const [value, setValue] = React.useState(0);
  const [openInfoBox, setOpenInfoBox] = useRecoilState(stateOpenInfoBox)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <Modal
      open={openInfoBox}
      onClose={() => setOpenInfoBox(false)}
      >    
    <Box sx={style}>
    <Typography  sx={{m: 1}} variant="h6">
    Hex5 Demo Applications
    </Typography>  
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 350 }}
    >
      <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
        {demoList.map( (demo, idx) => { return (
          <Tab icon={demo.icon} {...a11yProps(idx)} key={'tab'+idx} /> 
        )})}                
      </Tabs>
      {demoList.map( (demo, idx) => { return (
        <TabPanel value={value} index={idx} key={'tabpanel'+idx}>
          <Typography  sx={{m: 1}} variant="h6">
          {demo.desc}
          </Typography>     
          <List sx={{ p: 0, width: 200, bgcolor: 'background.paper' }}>
            <ExamplesList index={idx} />
          </List>
        </TabPanel>
      )})}        
    </Box>
    <Typography  sx={{m: 1}} variant="body2">
    This app is still in development mode! Interested in testing the API?
    Request a test user account at <a href="mailto:gabriel@fastinference.com">gabriel@fastinference.com</a>.
    </Typography>      
    <Grid container justifyContent="flex-end">
    <Button 
      variant="contained"
      onClick={() => setOpenInfoBox(false)}
      endIcon={<CancelPresentationIcon />}
    >Close</Button>
    </Grid>       
    </Box>
    </Modal>
  )
}

