import ReactMapGL from "react-map-gl"
import {Popup} from "react-map-gl"
// import {NavigationControl} from "react-map-gl"
import {_MapContext as MapContext} from 'react-map-gl'
import DeckGL from "@deck.gl/react"
import {H3ClusterLayer} from "@deck.gl/geo-layers"
// import {H3HexagonLayer} from "@deck.gl/geo-layers"
// import {PolygonLayer, SolidPolygonLayer} from '@deck.gl/layers'
import {stateSnackBar} from "../recoil/SnackBar"
import {MAPBOX_TOKEN, mapStyleActions} from '../helper/constants'
import {stateViewPort, stateMapStyle} from '../recoil/Map'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import { stateHex5Addr, stateMarker } from "../recoil/Marker"
import {GEO2HEX_API_URL, INIT_ZOOM_OFFSET, INIT_SPEED} from '../helper/constants'
import {FlyToInterpolator} from 'deck.gl'
import axios from 'axios'
import {stateInputHex5} from '../recoil/Hex5Coder'

// These lines are added to solve the mapbox transpiling issue
import mapboxgl from 'mapbox-gl'
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default



export default function Map() {

  const mapStyle = useRecoilValue(stateMapStyle)
  const [viewPort, setViewPort] = useRecoilState(stateViewPort)
  const [selectedMarker, setMarker] = useRecoilState(stateMarker)
  const hex5Addr = useRecoilValue(stateHex5Addr)
  const setDataSnackbar = useSetRecoilState(stateSnackBar)
  const setInputHex5 = useSetRecoilState(stateInputHex5)

  // -------------------------------------------------------
  // utility functions
  // -------------------------------------------------------

  const copyToClipboard = async () => {
    // this only works over https or localhost
    try {
      await navigator.clipboard.writeText(hex5Addr.join(" "))
      setDataSnackbar({
        open: true,
        type: "info",
        msg: "Address copied to clipboard"
      })
    } catch (err) {
      // document.execCommand('copy', true, hex5Addr.join(" "))
      setDataSnackbar({
        open: true,
        type: "warning",
        msg: "Address couldn't be copied to clipboard"
      })
      // console.log(err)
    }
  }

  // zoom to the current location
  const zoomToLocation = () => {
    setViewPort( {...viewPort, longitude: selectedMarker.longitude, 
      latitude: selectedMarker.latitude, 
      zoom: selectedMarker.resolution+INIT_ZOOM_OFFSET,
      transitionDuration: INIT_SPEED,
      transitionInterpolator: new FlyToInterpolator()    
    })
    setInputHex5( hex5Addr.join(" ") )
  }

  // click on a location
  const handleLocationClick = async (coord) => {
    await axios.get(GEO2HEX_API_URL + "?lat=" + coord[1] + "&lng=" + coord[0])
    .then(result => {
      if ('status' in result.data) {
        if (result.data.status === 'ok') {
          console.log( coord[1] + ',' + coord[0] + ' -> ' + result.data.hex5 )
          // setMarker( {...selectedMarker, longitude: coord[0], latitude: coord[1], 
          //   hex5: result.data.hex5, h3: result.data.h3} )
          setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
            hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
            boundary: result.data.boundary} )
        } else{
          setDataSnackbar({
            open: true,
            type: "warning",
            msg: result.data.desc
          })          
        }
      }})
    .catch(error => {
      setDataSnackbar({
        open: true,
        type: "error",
        msg: error.response.data.error
      })
    })         
  }

  // -------------------------------------------------------
  // DeckGL layers
  // -------------------------------------------------------

  const layers = [      
    // new H3HexagonLayer({
    //   id: "h3-hexagon-layer",
    //   data: [{"hex": selectedMarker.h3, "hex5": selectedMarker.hex5}],
    //   getHexagon: d => d.hex,
    //   pickable: false,
    //   wireframe: true,
    //   filled: true,
    //   extruded: true,
    //   elevationScale: 0,
    //   getLineColor: [0, 0, 0],
    //   getFillColor: [0, 0, 0, 1],
    //   opacity: 1
    // }), 
    new H3ClusterLayer({
      id: "h3-cluster-layer",
      wrapLongitude: true,
      data: [{hex: selectedMarker.h3, hex5: selectedMarker.hex5}],
      getHexagons: d => d.hex,
      pickable: false,
      wireframe: true,
      filled: true,
      extruded: true,
      elevationScale: 0,
      getFillColor: [255, 0, 0],
      opacity: 0.1,
      getLineColor: [255, 0, 0],
      getLineWidth: 5,
      lineWidthMinPixels: 5
    }),   
    // new SolidPolygonLayer({
    //   id: "polygon-layer",
    //   wrapLongitude: true,
    //   data: [{polygon: selectedMarker.boundary}],
    //   getPolygon: d => d.polygon,
    //   pickable: false,
    //   wireframe: true,
    //   filled: true,
    //   extruded: true,
    //   elevationScale: 0,
    //   getFillColor: [255, 0, 0],
    //   opacity: 0.1,
    //   getLineColor: [255, 0, 0],
    //   getLineWidth: 5,
    //   lineWidthMinPixels: 5
    // }),            
  ]

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DeckGL
        style={{ position: "relative" }}
        height={"100%"}
        width={"100%"}
        initialViewState={viewPort}
        onViewStateChange={({ viewState }) => setViewPort(viewState)}
        controller={true}
        ContextProvider={MapContext.Provider}
        layers={layers}
        onClick={(info) => handleLocationClick(info.coordinate)}
      >
        {/* <div style={{margin: 10, position: 'absolute', zIndex: 1, bottom: 100, right: 30}}>
          <NavigationControl />
        </div>         */}

        {hex5Addr[0] && 
        <Popup longitude={selectedMarker.longitude} latitude={selectedMarker.latitude}
            anchor="bottom"
            closeButton={false}
            closeOnClick={false}
            closeOnMove={false}
            onClick={() => copyToClipboard()}
            onDoubleClick={zoomToLocation}
        >
              {hex5Addr[0]}
              <br />
              {hex5Addr[1]}
        </Popup>
        }

        <ReactMapGL 
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={mapStyleActions[mapStyle].mapbox}
        >
        </ReactMapGL>
      </DeckGL> 
    </div>
  )
}


