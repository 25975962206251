import MapIcon from '@mui/icons-material/Map'
// import AddRoadIcon from '@mui/icons-material/AddRoad'
// import LandscapeIcon from '@mui/icons-material/Landscape'
import SatelliteIcon from '@mui/icons-material/Satellite'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import HomeIcon from '@mui/icons-material/Home'
import FactoryIcon from '@mui/icons-material/Factory'
import TableChartIcon from '@mui/icons-material/TableChart'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import StorefrontIcon from '@mui/icons-material/Storefront'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

// SecretInput
export const MIN_secret = 10    // # of min chars for the secret

// H3
export const VISIBLE_RES = 9
export const H3_RES = 13

// Map
export const MAP_HEIGHT = window.innerHeight
export const MAP_WIDTH = window.innerWidth
export const MIN_noH3Indices = 3
export const MAX_noH3Indices = 6

export const INIT_ZOOM_OFFSET = 5
export const GOTO_zoom = 20
export const INIT_SPEED = 5000

// Initial hex5 location
export const INIT_LAT = 40.77463435960559
export const INIT_LNG = -73.97276233119808

export const GEO2HEX_API_URL = "https://api-dot-hex5-364818.ue.r.appspot.com/demo/geo/"
export const HEX2GEO_API_URL = "https://api-dot-hex5-364818.ue.r.appspot.com/demo/hex5/"

// export const GEO2HEX_API_URL = "http://192.168.86.184:8000/demo/geo/"
// export const HEX2GEO_API_URL = "http://192.168.86.184:8000/demo/hex5/"

// MapBox
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicmFuZGNoZWNrIiwiYSI6ImNsNHJrbTJreTAwMGEzYnBndjBpMzg5OGUifQ.Z-WIkR2xzChLOTgZtuWA9w"

export const mapStyleActions = [
  { icon: <MapIcon />, name: 'Outdoors', mapbox: 'mapbox://styles/mapbox/outdoors-v11' },  
  { icon: <SatelliteIcon />, name: 'Satellite', mapbox: 'mapbox://styles/mapbox/satellite-streets-v11' },  
  // { icon: <MapIcon />, name: 'Basic', mapbox: 'mapbox://styles/mapbox/basic-v9' },
  // { icon: <AddRoadIcon />, name: 'Streets', mapbox: 'mapbox://styles/mapbox/streets-v11' },
  // { icon: <SatelliteIcon />, name: 'Satellite', mapbox: 'mapbox://styles/mapbox/satellite-v9' },
  // { icon: <MapIcon />, name: 'Basic', mapbox: 'mapbox://styles/mapbox/basic-v7' },
  // { icon: <LandscapeIcon />, name: 'Outdoors', mapbox: 'mapbox://styles/mapbox/outdoors-v9' },
]

// Demo List
export const demoList = [
  {
    desc: 'Infrastructure [Energy]',      
    icon: <FactoryIcon />,
    examples: [
      {
        name: 'Substation',
        lat: 35.09765,
        lng: -80.70200,
        hex5: '3531 Radar Depart Trim Duo'
      },
      {
        name: 'Distribution Tower',
        lat: 35.10295,
        lng: -80.70506,
        hex5: '2764 Patent Dentist Trim Duo'
      },   
      {
        name: 'Transmition Poll',
        lat: 35.10155,
        lng: -80.70911,
        hex5: '3552 Party Depart Trim Duo'
      },     
    ]
  },
  {
    desc: 'Multiple Buildings',
    icon: <TableChartIcon />,
    examples: [
      {
        name: 'Tower @ Factory',
        lat: 35.06940,
        lng: -80.87188,
        hex5: '4417 Pudding Civic Temp Duo'
      }, 
      {
        name: 'Building @ Complex',
        lat: 35.21042,
        lng: -80.93043,
        hex5: '7733 Pledge Congress Temp Duo'
      },  
      {
        name: 'Hangar @ Farm',
        lat: 34.86049,
        lng: -80.54503,
        hex5: '7547 Pen Busy Triumph Duo'
      },                
    ]
  },
  {
    desc: 'Multiple Entrances',      
    icon: <StorefrontIcon />,
    examples: [
      {
        name: 'Front Entrance',
        lat: 35.05336,
        lng: -80.76765,
        hex5: '2221 Plot Clone Temp Duo'
      },        
      {
        name: 'Side Entrance',
        lat: 35.05292,
        lng: -80.76752,
        hex5: '6431 Plot Clone Temp Duo'
      },    
      {
        name: 'Employee Entrance',
        lat: 35.05326,
        lng: -80.76693,
        hex5: '4143 Plot Clone Temp Duo'
      }, 
    ]
  },  
  {
    desc: 'Standard Address',      
    icon: <HomeIcon />,
    examples: [
      {
        name: 'Fiji House',
        lat: -17.83509,
        lng: 179.40920,
        hex5: '2247 Guard Select Aid Observe'
      },        
      {
        name: 'India House',
        lat: 23.71544,
        lng: 77.09484,
        hex5: '2341 Margin Gravity Seek Diamond'
      },    
      {
        name: 'Smoky Mnt Cabin',
        lat: 35.25270,
        lng: -82.85107,
        hex5: '2622 Redeem Change Vintage Duo'
      }, 
    ]
  },
  {
    desc: 'Emergency',      
    icon: <MedicalServicesIcon />,
    examples: [
      {
        name: 'Park Trail',
        lat: 35.07411,
        lng: -80.83303,
        hex5: '2163 Puppy Class Temp Duo'
      },
      {
        name: 'Appalachian Trail',
        lat: 35.58368,
        lng: -83.47831,
        hex5: '5216 String Carnival Piano Duo'
      },
      {
        name: 'Maritime',
        lat: 43.95660,
        lng: -59.88002,
        hex5: '1217 Ship Jar Path Civic'
      },
    ]
  },
  {
    desc: 'Military',      
    icon: <MilitaryTechIcon />,
    examples: [
      {
        name: 'Hangar',
        lat: 36.94599,
        lng: -76.28199,
        hex5: '3661 Nothing Hammer Stove Citizen'
      },
      {
        name: 'Helipad',
        lat: 36.94844,
        lng: -76.28308,
        hex5: '6616 Melody Guild Stove Citizen'
      },            
      {
        name: 'Outpost',
        lat: 31.96986,
        lng: 35.42228,
        hex5: '7777 Marine Gorgeous Rule Coach'
      },            
    ]
  },
  {
    desc: 'Transportation',      
    icon: <LocalShippingIcon />,
    examples: [
      {
        name: 'Construction Site',
        lat: 35.14927,
        lng: -80.97897,
        hex5: '2376 Ozone Decade Temp Duo'
      },
      {
        name: 'Container Load',
        lat: 35.19805,
        lng: -80.95960,
        hex5: '5725 Peanut Deliver Temp Duo'
      },            
      {
        name: 'RV Address',
        lat: 35.24137,
        lng: -80.97293,
        hex5: '3271 Recipe Clutch Test Duo'
      },            
    ]
  },  
]