import { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
// import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import axios from 'axios'
import {GEO2HEX_API_URL, HEX2GEO_API_URL, INIT_SPEED, INIT_ZOOM_OFFSET} from '../helper/constants'
import {useRecoilState} from 'recoil'
import { stateMarker } from "../recoil/Marker"
import {stateViewPort} from '../recoil/Map'
import {FlyToInterpolator} from 'deck.gl'
import { stateInputHex5 } from '../recoil/Hex5Coder'

export default function Hex5Coder() {
    const [inputHex5, setInputHex5] = useRecoilState(stateInputHex5)
    const [errorHex5, setErrorHex5] = useState('')
    const [selectedMarker, setMarker] = useRecoilState(stateMarker)
    const [viewPort, setViewPort] = useRecoilState(stateViewPort)

    // -------------------------------------------------------
    // geo2hex5
    // -------------------------------------------------------
    const geo2hex5 = async () => {

        // set error to none
        setErrorHex5('')

        // check whether the input is (lat, lng)
        const reTest = /^\s*((\-?|\+?)?\d+(\.\d+)?)\s*,?\s*((\-?|\+?)?\d+(\.\d+)?)\s*$/gi
        const valid = reTest.test(inputHex5)
    
        const reMatch = /((\-?|\+?)?\d+(\.\d+)?)/gi
        if (valid) {
            // this is (lat, lng) 
            const match = inputHex5.match(reMatch)
            await axios.get(GEO2HEX_API_URL + "?lat=" + match[0] + "&lng=" + match[1])
            .then(result => {
                if ('status' in result.data) {
                    if (result.data.status === 'ok') {   
                        setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
                            hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
                            boundary: result.data.boundary} )
                        setViewPort( {...viewPort, longitude: result.data.lng, 
                            latitude: result.data.lat, 
                            zoom: result.data.resolution+INIT_ZOOM_OFFSET,
                            transitionDuration: INIT_SPEED,
                            transitionInterpolator: new FlyToInterpolator() 
                        })
                    } else {
                        setErrorHex5( result.data.desc )
                    } 
                }                                        
            })
            .catch(error => {
                setErrorHex5( error.response.data.error )
            })               
        } else {
            // this is probably a hex5 address
            await axios.get(HEX2GEO_API_URL + "?hex5_addr=" + inputHex5)
            .then(result => {
                if ('status' in result.data) {
                    if (result.data.status === 'ok') {
                        // console.log( result.data.boundary )
                        console.log( result.data.hex5 + ' -> ' + result.data.lat + ',' + result.data.lng )
                        setMarker( {...selectedMarker, longitude: result.data.lng, latitude: result.data.lat, 
                            hex5: result.data.hex5, h3: result.data.h3, resolution: result.data.resolution,
                            boundary: result.data.boundary} )
                        setViewPort( {...viewPort, longitude: result.data.lng, 
                            latitude: result.data.lat, 
                            zoom: result.data.resolution+INIT_ZOOM_OFFSET,
                            transitionDuration: INIT_SPEED,
                            transitionInterpolator: new FlyToInterpolator()     
                        })
                    } else {
                        setErrorHex5( result.data.desc )
                    }
                }
            })
            .catch(error => {
                setErrorHex5( error.response.data.error )
            })             
        }
    }  

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------     
  return (
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '35ch', background: '#fff' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" 
        label="Hex5 Address" 
        value={inputHex5}
        error={errorHex5.length > 0}
        helperText={errorHex5}
        variant="filled"
        onChange={(e) => {
            setInputHex5(e.target.value) 
            setErrorHex5('')
        }}
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
              geo2hex5()
            }
        }}
        InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" aria-label="upload picture" component="label" onClick={(e) => geo2hex5()}>
                    <SearchIcon />
                </IconButton>                
                {/* <Button 
                    variant="outlined" 
                    color="primary" 
                    size="small" 
                    endIcon={<SearchIcon />}
                    onClick={(e) => geo2hex5() }
                >Search
                </Button> */}
              </InputAdornment>
            )
          }}        
        />

    </Box>
  );
}