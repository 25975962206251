import {atom} from 'recoil';
import {INIT_LAT, INIT_LNG, MAP_HEIGHT, MAP_WIDTH} from '../helper/constants'
import {MapView} from '@deck.gl/core'

export const stateMapStyle = atom({
  key: "mapstyle",
  // default: mapStyleActions[0].mapbox
  default: 0
})

export const stateViewPort = atom({
    key: "viewport",
    default: {
        views: new MapView({repeat: false}),
        latitude: INIT_LAT, 
        longitude: INIT_LNG,
        zoom: 14,
        maxZoom: 24,
        height: MAP_HEIGHT,
        width: MAP_WIDTH,
        padding: 30,
        pitch: 0,
        bearing: 0
    }
})