import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon'

const SvgHex5LogoSimple = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={202.947}
    height={175.757}
    viewBox="0 0 53.696 46.502"
    role="img"
    {...props}
  >
    <path
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 3.9,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "fill markers stroke",
      }}
      d="M58.614 30.994 46.316 52.295H21.72L9.42 30.994 21.72 9.693h24.596z"
      transform="translate(-7.17 -7.743)"
    />
    <g
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.58329999999999949px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        fill: "currentColor",
        fillOpacity: 1,
        stroke: "currentColor",
        strokeWidth: 0.264583,
      }}
    >
      <path
        d="M101.96 133.255h3.393q.638-1.154 1.447-1.62.81-.447 1.792-.447 1.102 0 1.843.31.74.379 1.12 1.016.706 1.344.671 3.497 0 .879-.034 1.809-.069.947-.379 1.757-.293.81-1.033 1.309-.776.517-2.068.517-3.014-.035-3.6-2.929h-3.6q.517 3.463 2.67 5.013 2.119 1.516 4.668 1.516 1.895-.035 3.273-.879 1.413-.81 2.153-1.791.844-1 1.206-2.291.345-1.31.345-3.755 0-1.585-.104-2.67-.103-1.068-.344-1.809-.465-1.36-1.516-2.394-.706-.775-1.843-1.31-1.206-.482-2.653-.516-2.257 0-4.014 1.378v-5.685h9.991v-3.6H101.96z"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "35.27779999999999916px",
          fontFamily: "'DIN Alternate'",
          InkscapeFontSpecification: "'DIN Alternate'",
          fill: "currentColor",
          stroke: "currentColor",
          strokeWidth: 0.264583,
        }}
        transform="translate(-81.821 -109.09)"
        aria-label={"5"}
      />
    </g>
  </SvgIcon>
);
export default SvgHex5LogoSimple;

